import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tools/get_default_line_liff', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/get_list_line_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/get_line_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/add_line_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/edit_line_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/delete_line_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    lineLiffOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/line_liff_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
